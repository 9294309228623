import * as React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Home from '../components/templates/Home/Home';

const Index = () => <Home />;

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'fr', ['home'])),
    },
  };
};

export default Index;
